<template>
  <div id="app">
    <transition
      mode="out-in"
      name="fade"
      v-if="loaded"
    >
      <router-view
        v-if="isLoaded"
      />

      <ErrorAlerta
        v-else
        :showBtn="errorConfig.showBtn"
        :texto="errorConfig.texto"
        :titulo="errorConfig.titulo"
      />
    </transition>
  </div>
</template>

<script>
import { axiosAPI, ConfiguradorResource, PlataformaBeneficiarioResource } from '@/API'
import { mapGetters, mapMutations, mapState } from 'vuex'
import ErrorAlerta from '@/components/Error/ErrorAlerta'
import mshubPayload from '@/utils/mshubPayload'
import { listaDeBaseRotasMSHub } from '@/utils/HandlerEnums'
import canal from '@/utils/canal'
import { isPublicRoute } from '@/utils/publicRoutes'

export default {
  name: 'App',
  components: { ErrorAlerta },
  created () {
    this.init()
  },
  data () {
    return {
      loaded: false,
      origemError: false,
      errorConfig: {
        titulo: 'Não Autorizado',
        texto: 'Você não tem permissão para acessar essa página.',
        showBtn: false
      }
    }
  },
  computed: {
    ...mapGetters('SetupApp', [
      'bundle',
      'bundleWeb', 'aplicacao',
      'configuracaoAcesso',
      'imagens',
      'instanciaAplicacao'
    ]),
    ...mapGetters('Beneficiario', ['user']),
    ...mapState('Beneficiario', ['beneficiarioCompleto']),
    ...mapState('Login', ['token']),
    favicon () {
      let favicon = this.imagens.favIconWeb
      return (typeof favicon === 'undefined' || favicon === '') ? 'favicon.ico' : favicon
    },
    isLoaded  () {
      return !this.origemError && this.loaded
    },
    instancia_aplicacao () {
      if (process.env.NODE_ENV === 'production') {
        return process.env.VUE_APP_INSTANCIA_APLICACAO_DEFAULT
      }
      const instanciaAplicacao = this.$route.query.instanciaAplicacao

      if (instanciaAplicacao) {
        return instanciaAplicacao
      }

      if (this.instanciaAplicacao) {
        return this.instanciaAplicacao
      }
      return process.env.VUE_APP_INSTANCIA_APLICACAO_DEFAULT
    }
  },
  metaInfo () {
    return {
      title: this.aplicacao.web.tituloPlataforma || 'Plataforma de Beneficiários',
      link: [
        { rel: 'favicon', href: this.favicon },
        { rel: 'icon', href: this.favicon }
      ]
    }
  },
  methods: {
    ...mapMutations('SetupApp', ['SET_CONFIG', 'SET_API_CMS', 'SET_ESQUEMA_DE_COR', 'SET_API_ACESSO']),
    ...mapMutations('Beneficiario', ['SET_LOGIN']),
    ...mapMutations('Login', ['SET_TOKEN']),
    async init () {
      let bundleWeb = window.location.hostname
      let data
      if (process.env.NODE_ENV !== 'development') {
        data = await ConfiguradorResource.validaOrigemPlataforma({ bundleWeb })

        if (!data) {
          this.origemError = true
          return
        }
      } else {
        let devBundle = this.$route.query.bundle

        if (!devBundle) {
          devBundle = this.bundle
        }

        if (!devBundle) {
          devBundle = window.prompt('Informe o bundle de desenvolvimento:')
        }

        data = {
          bundle: devBundle
        }
      }

      let bundle = data && data.bundle

      const result = await ConfiguradorResource.getSetupApp({
        bundle,
        bundleWeb,
        instanciaAplicacao: this.instancia_aplicacao,
        canalAtendimento: canal
      })

      await this.SET_CONFIG({
        config: result,
        bundle: result.aplicacao.bundle,
        bundleWeb,
        plataforma: true,
        instanciaAplicacao: this.instancia_aplicacao
      })

      this.configureRotasCMS()
      this.configuradorRotasAcesso()

      if (this.token && this.token.length !== 0) {
        const beneficiario = await PlataformaBeneficiarioResource.carregar()

        if (beneficiario.status) {
          this.SET_LOGIN(beneficiario.data)
        } else {
          try { await this.apiAcesso.login.logout() } catch (ignored) { }
          this.SET_TOKEN('')
          await this.$router.push({ path: `/?expirado=true` })
        }
      } else {
        try { await this.apiAcesso.login.logout() } catch (ignored) { }
        if (this.$router.currentRoute.path !== '/' && !isPublicRoute(this.$router.currentRoute.path)) {
          await this.$router.push({ path: '/' })
        }
      }

      const esquemaCor = this.user.esquemaCor

      await this.aplicaEsquemaCor(esquemaCor, bundle, bundleWeb)

      this.loaded = true
    },
    configureRotasCMS () {
      const rotasCMS = {
        'cms': {
          'path': '',
          'urlBase': 'cms',
          'metodo': 'POST',
          'contentType': 'application/json'
        },
        'banners': {
          'path': '',
          'urlBase': 'banners',
          'metodo': 'POST',
          'contentType': 'application/json'
        },
        'termosUsoUltimoValido': {
          'path': '',
          'urlBase': 'termosUsoUltimoValido',
          'metodo': 'POST',
          'contentType': 'application/json'
        },
        'termosUsoConfirmarAceite': {
          'path': '',
          'urlBase': 'termosUsoConfirmarAceite',
          'metodo': 'POST',
          'contentType': 'application/json'
        },
        'termosUsoVerificarAceite': {
          'path': '',
          'urlBase': 'termosUsoVerificarAceite',
          'metodo': 'POST',
          'contentType': 'application/json'
        }
      }
      const apiRotasCMS = this.configuradorRotas(this.aplicacao.rotas, rotasCMS)

      this.SET_API_CMS(apiRotasCMS)
    },
    configuradorRotasAcesso () {
      let apiRotasAcesso = {}

      let acesso = this.configuracaoAcesso
      Object.keys(acesso).forEach((k) => {
        if (!acesso[k].ativo || !acesso[k].rotas) {
          return
        }
        apiRotasAcesso[k] = this.configuradorRotas(this.aplicacao.rotas, acesso[k].rotas)
      })

      this.SET_API_ACESSO(apiRotasAcesso)
    },
    async aplicaEsquemaCor (esquemaCor, bundle, bundleWeb) {
      try {
        const res = await ConfiguradorResource.getEsquemaCor({
          bundle,
          bundleWeb,
          esquemaCor
        })

        if (esquemaCor && !res) {
          await this.aplicaEsquemaCor(undefined, bundle, bundleWeb)
        }

        this.SET_ESQUEMA_DE_COR(res)
      } catch (ignored) {}
    },
    configuradorRotas (rotasBase, rotas) {
      const apiRotas = {}

      if (!rotas) {
        return apiRotas
      }
      Object.keys(rotas).forEach((k) => {
        if (rotasBase[rotas[k].urlBase]) {
          apiRotas[k] = async (opt) => {
            opt = opt || {}
            let payload = opt.payload || {}
            const customHeaders = opt.headers || {}
            const queryParams = opt.query || {}
            const params = opt.params
            const loading = opt.loading
            const notifyFailure = opt.notifyFailure
            const notifySuccess = opt.notifySuccess
            const handlerErrorIgnore = opt.handlerErrorIgnore
            const options = opt.options || {}

            let loader
            if (loading) {
              loader = this.$loading({
                lock: true,
                fullscreen: true,
                text: typeof loading === 'string' ? loading : '',
                customClass: 'myloading'
              })
            }

            const method = rotas[k].metodo.toLowerCase()
            let url = rotasBase[rotas[k].urlBase].rota + rotas[k].path

            if (params) {
              Object.keys(params).forEach((key) => {
                url = url.replace(`:${key}`, params[key])
              })
            }

            const headers = {
              'content-type': rotas[k].contentType,
              instancia_aplicacao: this.instanciaAplicacao,
              ...customHeaders
            }

            let data

            if (listaDeBaseRotasMSHub.indexOf(rotas[k].urlBase) !== -1) {
              payload = mshubPayload(payload)
              headers.mshash = this.aplicacao.mshash
            }

            headers.bundle = this.bundle

            switch (rotas[k].contentType) {
              case 'multipart/form-data':
                const formData = new FormData()

                Object.keys(payload).forEach((key) => {
                  formData.append(key, payload[key])
                })

                data = formData
                break

              case 'application/json':
              default:
                data = {
                  ...payload
                }
            }

            const request = axiosAPI({
              method,
              url,
              data,
              headers,
              params: queryParams
            })

            const optionsData = {
              ...options,
              notifyFailure,
              notifySuccess,
              handlerErrorIgnore
            }

            const result = await axiosAPI.notifyRequest(request, optionsData)

            if (loading) {
              setTimeout(() => {
                loader.close()
              }, 300)
            }

            return result
          }
        }
      })

      return apiRotas
    }
  }
}
</script>
