<template>
  <el-row v-if="!isMobile" style="height: 100vh;">
    <el-col :span="12" style="height: 100%;">
      <div
        class="grid-content bg-img area-beneficiario"
      >
        <el-image
          v-if="bundle"
          style="width: 100%; height: 100%; opacity: 0.2; position: absolute;"
          :src="backgroundBanner"
          fit="cover"
        />
        <div class="box-area">
          <div class="box-area-beneficiario">
            <el-image
              style="height: 100px"
              :src="clienteLogo"
              fit="contain"
            />
            <h1 class="box-area-mensagem">
              {{ this.aplicacao.textos.textoBoasVindasBeneficiarioWeb }}
            </h1>
          </div>
        </div>
        <div class="rodape-beneficiario">
          <div class="rodape-dev-por">Desenvolvido por</div>
          <el-image
            style="width: 106px; height: 16px;"
            :src="msLogo"
            fit="contain"
          />
        </div>
      </div>
    </el-col>
    <el-col :span="12" style="height: 100%;">
      <div class="grid-content bg-login">
        <div v-if="!isMobile && !isUnsupportedBrowser" id="func-login" class="func-login"/>
        <BrowserWarning v-else :navegador="browser"/>
      </div>
    </el-col>
    <DialogDefault
      :open.sync="dialogExpirado"
      title="Sessão expirada"
      width="375px"
      top="25%"
      @close="() => { dialogExpirado = false }"
      :body="`Sua sessão foi expirada.`"
    />
  </el-row>
  <el-row v-else>
    <MobileWarning/>
  </el-row>
</template>

<script>
import { mobileSaudeLogo } from '@/utils/ImageBucket'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { encodeEmbedData } from '@/utils/utils'
import { ConfiguradorResource } from '@/API'
import canal from '@/utils/canal'
import DialogDefault from '@/components/DialogDefault/DialogDefault'
import BrowserWarning from '@/components/login/BrowserWarning.vue'
import MobileWarning from '@/components/login/MobileWarning.vue'
import Bowser from 'bowser'

export default {
  name: 'Login',
  components: { DialogDefault, BrowserWarning, MobileWarning },
  mounted () {
    this.init()
  },
  data () {
    return {
      titulo: '',
      dialogExpirado: false,
      isMobile: false,
      browser: '',
      supportedEngines: [
        'Blink' // Motores baseados no Chromium
      ]
    }
  },
  computed: {
    ...mapGetters('Beneficiario', ['user']),
    ...mapState('Login', ['token']),
    ...mapGetters('SetupApp', ['bundle', 'bundleWeb', 'aplicacao', 'tabBar', 'dashBoard', 'estilo', 'imagens', 'instanciaAplicacao']),
    backgroundBanner () {
      return this.imagens.backgroundWeb
    },
    clienteLogo () {
      return this.imagens.logoLoginWeb
    },
    msLogo () {
      return mobileSaudeLogo()
    },
    favicon () {
      let favicon = this.imagens.favIconWeb
      return (typeof favicon === 'undefined' || favicon === '') ? 'favicon.ico' : favicon
    },
    queryExpirado () {
      return !!this.$route.query.expirado
    },
    isUnsupportedBrowser () {
      return !this.supportedEngines.includes(this.browser.engine.name)
    }
  },
  metaInfo () {
    return {
      title: `${this.aplicacao.web.tituloPlataforma} - ${this.titulo}`,
      link: [
        { rel: 'favicon', href: this.favicon },
        { rel: 'icon', href: this.favicon }
      ]
    }
  },
  methods: {
    ...mapMutations('Beneficiario', ['SET_LOGIN']),
    ...mapMutations('Login', ['SET_TOKEN']),
    ...mapMutations('SetupApp', ['SET_ESQUEMA_DE_COR']),
    async init () {
      this.detectDevice()
      await this.aplicaEsquemaCor(undefined, this.bundle, this.bundleWeb)
      this.initFunc()

      if (this.queryExpirado) {
        this.$router.replace({ query: [] })
        this.dialogExpirado = true
      }
    },
    detectDevice () {
      this.browser = Bowser.parse(window.navigator.userAgent)
      this.isMobile = this.browser.platform.type === 'mobile'
    },
    initFunc () {
      const urlBase = process.env.NODE_ENV !== 'development' ? window.location.origin : ''
      const urlFuncionalidades = process.env.VUE_APP_FUNCIONALIDADES_FRONT

      let url = urlBase + urlFuncionalidades

      if (urlFuncionalidades.startsWith('http')) {
        url = urlFuncionalidades
      }

      let funcionalidade = window.omnichannelSDK.embed({
        id: 'func-login',
        url,
        json: {
          idFuncionalidade: 9,
          bundle: encodeEmbedData(this.bundle),
          plataforma: true,
          // clientHeight: '90vh',
          maxHeight: `auto`,
          height: parseInt(`${window.screen.height * 0.9}`),
          canal: canal,
          instanciaAplicacao: this.instanciaAplicacao
        }
      })

      funcionalidade.on('CREATED', (response) => {
      })

      funcionalidade.on('LOADING', (response) => {
      })

      funcionalidade.on('LOGIN', (response) => {
        this.login(response)
      })

      funcionalidade.on('TROCA_PAGINA', (response) => {
        this.titulo = response.titulo
      })
    },
    async aplicaEsquemaCor (esquemaCor, bundle, bundleWeb) {
      try {
        const res = await ConfiguradorResource.getEsquemaCor({
          bundle,
          bundleWeb,
          esquemaCor
        })
        this.SET_ESQUEMA_DE_COR(res)
      } catch (ignored) {
      }
    },
    gotoDashboard () {
      this.$router.push('/dashboard/inicio')
    },
    login (response) {
      this.SET_LOGIN(response.data)
      this.SET_TOKEN(response.data.token)
      this.aplicaEsquemaCor(this.user.esquemaCor, this.bundle, this.bundleWeb)
      this.gotoDashboard()
    }
  }
}
</script>

<style scoped>
.area-beneficiario {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}

.bg-img {
  background: #EEEEEE;
}

.grid-content {
  height: 100%;
}

.bg-login {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 20px #0000000D;
  display: flex;
  flex-flow: column nowrap;
  height: 95vh;
  padding-top: 5vh;
  padding-right: 15vh;
  padding-left: 15vh;
}

.box-area {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.box-area-beneficiario {
  max-width: 435px;
  height: 316px;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 20px #0000000D;
  border-radius: 20px;
  opacity: 0.9;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.box-area-mensagem {
  text-align: center;
  font: normal normal 300 32px/43px Open Sans;
  color: #000000;
}

.rodape-beneficiario {
  background: #FFFFFF 0 0 no-repeat padding-box;
  opacity: 0.5;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 36px;
}

.rodape-dev-por {
  text-align: right;
  font: normal normal normal 11px/13px Open Sans;
  letter-spacing: 0;
  color: #333333;
  opacity: 1;
  margin-right: 5px;
}

.func-login {
  width: 100%;
  height: 100%;
}
</style>
